
































































































































































































































































































































































































































































































  import { debounceInput, isMobileScreenWidth } from '@/shared/helpers'
  import { CompanyRole, IUserSubsegment } from '@/shared/model/company.model'
  import { IUser, UserRole } from '@/shared/model/user.model'
  import companyService from '@/shared/services/companyService'
  import userService from '@/shared/services/userService'
  import Vue from 'vue'
  import { mapGetters } from 'vuex'

  export default Vue.extend({
    data () {
      return {
        dialog: false,
        invitation: { email: '', companyRoles: [] },
        editDialog: false,
        search: undefined,
        selectedUser: null as IUserSubsegment | null,

        companyRoles: [CompanyRole.employee, CompanyRole.evalClient],
        selectedRole: '' as CompanyRole,

        temporaryReSubmittedInvitations: [] as string[],

        addCoworkerDialog: false,
        userAutocomplete: '',
        debounce: undefined as number | undefined,
        selectedCoworker: '' as string,
        selectedCoworkers: [] as IUser[],
        autocompleteError: '' as string,
        isMobile: isMobileScreenWidth(),
      }
    },
    computed: {
      ...mapGetters({
        company: 'company',
        account: 'account',
        users: 'userStore/getList',
      }),
      headers (): any {
        return [
          {
            text: this.$t('username'),
            value: 'user.username',
          },
          {
            text: this.$t('firstname'),
            value: 'user.metadata.firstName',
          },
          {
            text: this.$t('lastname'),
            value: 'user.metadata.lastName',
          },
          {
            text: this.$t('email'),
            value: 'user.email',
          },
          {
            text: this.$t('roles'),
            value: 'companyRoles',
          },
          {
            text: this.$t('valid-certificate'),
            value: 'user.validCertificate',
            align: 'end',
          },
          {
            text: this.$t('level'),
            value: 'user.leadingCertificate.level',
            align: 'end',
          },
          {
            text: this.$t('due-date'),
            value: 'user.leadingCertificate.dueDate',
            align: 'end',
          },
          {
            sortable: false,
            text: this.$t('actions'),
            value: 'actions',
            align: 'end',
          },
        ]
      },
      userIsAtLeastEvalClient: function () {
        if (this.company && this.company.users) {
          const user = this.company.users.find((x: IUserSubsegment) => {
            if (x.user && x.companyRoles) {
              return x.user._id === this.account._id
            }
          })
          if (user) {
            if (user.companyRoles.includes(CompanyRole.evalClient)) {
              return true
            }
          }
        }
        return false
      },
      userIsSystemAdmin () {
        return userService.userIsSystemAdmin()
      },
    },
    watch: {
      userAutocomplete: {
        async handler () {
          this.autocompleteError = ''
          this.debounce = debounceInput(this.debounce, this.getAllUsersForAutocomplete)
        },
      },
    },
    async mounted () {
      const id = this.$route.params.id
      if (!id) {
        await companyService.getCompany(this.$store)
      }
    },
    methods: {
      customFilter (value: any, search: any, item: any) {
        if (item.user && item.user.metadata && item.user.metadata.firstName && item.user.metadata.lastName) {
          item.user.fullName = item.user.metadata.firstName + ' ' + item.user.metadata.lastName
          if (item.companyRoles) {
            item.user.companyRoles = item.companyRoles
          }

          return Object.values(item.user).some((v: any) => v && v.toString().toLowerCase().includes(search.toLowerCase()))
        } else {
          return Object.values(item).some((v: any) => v && v.toString().toLowerCase().includes(search.toLowerCase()))
        }
      },
      getCompany () {
        const id = this.$route.params.id
        companyService.getCompany(this.$store, id)
      },
      sendInvite () {
        companyService
          .inviteUser(this.company._id, this.invitation)
          .then(() => {
            this.dialog = false
            this.getCompany()
            this.invitation = { email: '', companyRoles: [] }
          })
          .catch((e) => {
            if (e.response.status === 400) {
              if (e.response.data === 'user already exists in company') {
                this.$toast.error(this.$t('dialog.invite-user-already-exists-in-company').toString())
              } else if (e.response.data === 'user already exists in other company') {
                this.$toast.error(this.$t('dialog.invite-user-already-exists-in-other-company').toString())
              } else {
              }
            }
          })
      },
      clickInviteUser () {
        this.dialog = true
      },
      clickSendInvitationAgain (invitationToken: string) {
        companyService.inviteUserAgain(invitationToken).then(() => {
          this.temporaryReSubmittedInvitations.push(invitationToken)
        })
      },
      clickUpdateUser (item: IUserSubsegment) {
        // this.$router.push(`/app/users/${item._id}`, () => {})
        this.selectedUser = item
        if (item.companyRoles) {
          this.selectedRole = item.companyRoles[0]
        }
        this.editDialog = true
      },
      clickSaveCoworkers () {
        // this.$router.push(`/app/users/${item._id}`, () => {})
        if (this.selectedCoworkers.length) {
          const users = [...this.company.users]
          for (const coworker of this.selectedCoworkers) {
            users.push({
              user: coworker,
              companyRoles: [CompanyRole.employee],
            })
          }
          companyService
            .updateCompany(this.$store, { ...this.company, users: users })
            .then((result) => {
              this.addCoworkerDialog = false
              this.selectedCoworkers = []
              this.selectedCoworker = ''
              this.$toast(this.$t('dialog.save-changes-success').toString())
              companyService.getCompany(this.$store, this.company._id)
            })
            .catch((e) => {
              this.$toast.error(this.$t('dialog.save-company-failed').toString())
              companyService.getCompany(this.$store, this.company._id)
            })
        }
      },
      clickSaveCompanyRoles () {
        if (this.selectedUser && this.selectedRole) {
          this.selectedUser.companyRoles = [this.selectedRole]
        }
        companyService.updateCompany(this.$store, this.company).then((result) => {
          this.getCompany()
          this.selectedUser = null
          this.editDialog = false
        })
      },
      clickRemoveUser (user: IUser) {
        this.$confirm(
          `${this.$t('dialog.do-you-really-want-the-user-to')}
<strong>${user.username}</strong>
${this.$t('dialog.remove-from-company')}`,
          {
            color: 'error',
            buttonTrueText: this.$t('yes').toString(),
            buttonFalseText: this.$t('no').toString(),
            title: this.$t('dialog.delete-user').toString(),
          },
        ).then((res) => {
          if (res) {
            if (user._id) {
              const index = this.company.users.findIndex((y: any) => y.user && y.user._id === user._id)
              if (index >= 0) {
                this.company.users.splice(index, 1)
              }
              companyService.updateCompany(this.$store, this.company).then((result) => {
                this.getCompany()
              })
            } else {
              console.error('Tried to remove user without id, this should not happen')
            }
          }
        })
      },
      clickRemoveInvitation (email: string) {
        this.$confirm(
          `${this.$t('dialog.do-you-really-want-the-invitation-to')}
<strong>${email}</strong>
${this.$t('dialog.remove-from-company')}`,
          {
            color: 'error',
            buttonTrueText: this.$t('yes').toString(),
            buttonFalseText: this.$t('no').toString(),
            title: this.$t('dialog.delete-user').toString(),
          },
        ).then((res) => {
          if (res) {
            const index = this.company.users.findIndex((y: IUserSubsegment) => y.invitationEmail === email)
            if (index >= 0) {
              this.company.users.splice(index, 1)
            }
            companyService.updateCompany(this.$store, this.company).then((result) => {
              this.getCompany()
            })
          } else {
            console.error('Tried to remove user without id, this should not happen')
          }
        })
      },
      userIsAllowedToEditCoworker: function (coworker: IUserSubsegment) {
        if (this.userIsAtLeastEvalClient || this.account.roles.includes(UserRole.admin)) {
          if (this.userIsLastEvalClient(coworker)) {
            return false
          }
          return true
        }
        return false
      },
      userIsAllowedToDeleteCoworker: function (coworker: IUserSubsegment) {
        if (!this.userIsAtLeastEvalClient && !this.userIsSystemAdmin) {
          return false
        }
        if (coworker.user && coworker.user.uuid !== this.account.uuid) {
          if (coworker.companyRoles && !coworker.companyRoles.includes(CompanyRole.evalClient)) {
            return true
          }
        } else if (!coworker.user && coworker.invitationEmail) {
          return true
        }
        return false
      },
      invitationHasBeenSentAgain: function (token: string) {
        return !!this.temporaryReSubmittedInvitations.find((x) => x === token)
      },
      addUserToCompany () {
        if (this.selectedCoworker) {
          companyService.getUsersCompany(this.selectedCoworker).then((result) => {
            if (result.data && result.data.length) {
              this.autocompleteError = this.$t('dialog.this-user-is-already-in-a-company').toString()
            } else {
              const user = this.users.find((x: IUser) => x._id === this.selectedCoworker)
              if (user) {
                if (!this.selectedCoworkers.find((x: IUser) => x._id === user._id)) {
                  this.selectedCoworkers.push(user)
                  this.selectedCoworker = ''
                } else {
                  this.autocompleteError = this.$t('dialog.this-user-is-already-selected').toString()
                }
              }
            }
          })
        }
      },
      async getAllUsersForAutocomplete () {
        const query = {
          l: 10,
          lo: 0,
          sf: 'username',
          so: 'desc',
          search: { search: this.userAutocomplete },
        }
        await userService.getAllUsersPaginated(query)
      },
      userIsLastEvalClient (coworker: IUserSubsegment) {
        const coworkers = this.company.users
        let evalClientFound = false
        for (let i = 0; i < coworkers.length; i++) {
          if (coworkers[i].companyRoles && coworkers[i].companyRoles.includes(CompanyRole.evalClient)) {
            if (evalClientFound) {
              return false
            }
            evalClientFound = true
          }
        }
        if (coworker.companyRoles && coworker.companyRoles.includes(CompanyRole.evalClient)) {
          return true
        }
        return false
      },
      clickSelectCoworker (user: IUserSubsegment) {
        if (user.user) {
          this.$router.push(`/app/coworker/${user.user.uuid}`)
        }
      },
    },
  })
